import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { translateRoute } from 'utility/routing/common/routing';

function DownloadInSystems() {
  let [systemarr, setsystemarr] = useState({ data: [] });
  let [viewAll] = useState(true);

  let la;
  let arrla;
  let param = useParams();
  let para = useParams();

  let arrla1 = '';
  if (param.count) {
    la = param.count;
    arrla = la.split('-');
    if (param.count == 'en') {
      arrla = ['en', 'INTERNATIONAL'];
    }
    if (param.count == 'es') {
      arrla = ['es', 'INTERNATIONAL'];
    }
    if (arrla[1]) {
      arrla[1] = arrla[1].toUpperCase();
      arrla1 = arrla[0] + '-' + arrla[1];
      if (arrla[1] == 'INTERNATIONAL') {
        arrla1 = arrla[0];
      }
    }
  } else {
    arrla1 = 'en';
  }

  let token = process.env.REACT_APP_PUBLIC_KEY2;
  const requestOptions2 = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  };
  const translations2 = {
    'da-dk': ['Downloads efter system', 'Se alle systemer', 'Skjul systemer'],
    'de-at': ['Downloads nach System', 'Alle Systeme anzeigen', 'Systeme einklappen'],
    'de-ch': ['Downloads nach System', 'Alle Systeme anzeigen', 'Systeme einklappen'],
    'de-de': ['Downloads nach System', 'Alle Systeme anzeigen', 'Systeme einklappen'],
    'x-default': ['Downloads by system', 'View all systems', 'Collapse systems'],
    'en-ca': ['Downloads by system', 'View all systems', 'Collapse systems'],
    'en-gb': ['Downloads by system', 'View all systems', 'Collapse systems'],
    'en-ie': ['Downloads by system', 'View all systems', 'Collapse systems'],
    'en-us': ['Downloads by system', 'View all systems', 'Collapse systems'],
    es: ['Descargas por sistema', 'Ver todos los sistemas', 'Ocultar sistemas'],
    'es-es': ['Descargas por sistema', 'Ver todos los sistemas', 'Ocultar sistemas'],
    'es-us': ['Descargas por sistema', 'Ver todos los sistemas', 'Ocultar sistemas'],
    'fi-fi': ['Lataa järjestelmän mukaan', 'Näytä kaikki järjestelmät', 'Piilota järjestelmät'],
    'fr-be': ['Téléchargements par système', 'Voir tous les systèmes', 'Réduire les systèmes'],
    'fr-ca': ['Téléchargements par système', 'Voir tous les systèmes', 'Réduire les systèmes'],
    'fr-ch': ['Téléchargements par système', 'Voir tous les systèmes', 'Réduire les systèmes'],
    'fr-lu': ['Téléchargements par système', 'Voir tous les systèmes', 'Réduire les systèmes'],
    'it-ch': ['Download per sistema', 'Visualizza tutti i sistemi', 'Nascondi sistemi'],
    'it-it': ['Download per sistema', 'Visualizza tutti i sistemi', 'Nascondi sistemi'],
    'nb-no': ['Nedlastinger etter system', 'Vis alle systemer', 'Skjul systemer'],
    'nl-nl': ['Downloads per systeem', 'Bekijk alle systemen', 'Verberg systemen'],
    'nl-be': ['Downloads per systeem', 'Bekijk alle systemen', 'Verberg systemen'],
    'pl-pl': ['Pobieranie według systemu', 'Zobacz wszystkie systemy', 'Ukryj systemy'],
    'pt-br': ['Downloads por sistema', 'Ver todos os sistemas', 'Ocultar sistemas'],
    'pt-pt': ['Downloads por sistema', 'Ver todos os sistemas', 'Ocultar sistemas'],
    'sv-se': ['Nedladdningar per system', 'Visa alla system', 'Dölj system'],
  };
  const locale = para.count;

  const translatedSpecialText = translations2[locale] || translations2['x-default'];

  useEffect(() => {
    try {
      const cachedData = localStorage.getItem(para.count + '-cachedDataSystemCards');
      if (cachedData) {
        let now = new Date();
        setsystemarr(JSON.parse(cachedData).json);

        if (now.getTime() > JSON.parse(cachedData).expiry) {
          localStorage.removeItem(para.count + '-cachedDataSystemCards');
        }
      } else {
        let now = new Date();

        fetch(
          'https://strapi-qrailing.azurewebsites.net/api/systems?locale=' +
            arrla1 +
            '&populate=CardImage&sort[0]=Priority:desc&pagination[pageSize]=50',
          requestOptions2,
        )
          .then((res) => res.json())
          .then((json) => {
            setsystemarr(json);

            localStorage.setItem(
              para.count + '-cachedDataSystemCards',
              JSON.stringify({ json, expiry: now.getTime() + 24 * 60 * 60 * 1000 }),
            );
          });
      }
    } catch {
      /* empty */
    }
  }, []);

  return (
    <div className="DownloadsInSystems-wrap">
      <div className="DownloadsInSystems-inner">
        <div className="DownloadsInSystems-Headline">
          <h2>{translatedSpecialText[0]}</h2>
        </div>
        <div className="DownloadsInSystems-Buttons">
          {systemarr.data.length > 0 && (
            <div className="SystemEntryWrap">
              <div className="SystemSearchLinks">
                {(viewAll ? systemarr.data : systemarr.data.slice(0, 6)).map((system, index) => (
                  <Link
                    className="SystemEntry"
                    key={index}
                    to={`${translateRoute('balustrades/systems/', para.count)}${system.attributes.Slug}/#downloads`}
                  >
                    {system.attributes.ShortDisplayName ? system.attributes.ShortDisplayName : system.attributes.Title}
                  </Link>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default DownloadInSystems;
