import { Route, useLocation } from 'react-router-dom';
import { BaseRouter } from './routers/base-router';
import React, { useEffect, lazy } from 'react';
import { useTranslation } from 'react-i18next';
import { Utility } from '../utility';
import { lazyLoadComponentRetry } from './common/routing';

import Qsupport from '../../components/Qsupport';
import { DefaultRouter } from './routers/default-router';
import { LegacyRouter } from './routers/legacy-router';

const Permalink = lazy(() => lazyLoadComponentRetry(() => import('../../components/Permalink')));

export const RoutesComponent = () => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const localePath = location.pathname.split('/').filter((x) => x && x !== '')[0];

  // set the correct locale for i18n for later purposes when we divide location from language
  if (localePath !== i18n.language.toLowerCase() && Utility.isLocaleValid(localePath)) {
    i18n.changeLanguage(localePath);
  }

  // make routes pretty
  useEffect(() => {
    const filteredPathName = location.pathname.split('/').filter((x) => x && x.trim() !== '');
    if (filteredPathName.length === 0) {
      return;
    }
    const isFile = filteredPathName[filteredPathName.length - 1]?.includes('.');
    if (isFile) {
      if (location.pathname.endsWith('/')) {
        window.history.pushState(
          null,
          '',
          '/' +
            location.pathname
              .split('/')
              .filter((x) => x && x.trim() !== '')
              .join('/'),
        );
      }
    } else if (!location.pathname.match(/\w\/$/)) {
      window.history.pushState(
        null,
        '',
        '/' +
          location.pathname
            .split('/')
            .filter((x) => x && x.trim() !== '')
            .join('/') +
          '/',
      );
    }
  }, [location]);

  return (
    <>
      {/* special cases hardcoded and not inside the :locale scope */}
      <Route path="/_*" element={<LegacyRouter />} />
      <Route path="/support" element={<Qsupport />} />
      <Route path="/Permalink" element={<Permalink />} />
      <Route path="/q-disc" element={<Permalink />} />

      {/* entry route, redirects user to locale according to ip address */}
      <Route path="/" element={<BaseRouter />} />

      {/* default route, has to be called instead of </> since it would count as extra component */}
      {/* also render conditionally to prevent Permalinks with only one segment to be routed here */}
      <Route path="/:count">{DefaultRouter()}</Route>
      <Route path="/*" element={<LegacyRouter />} />
    </>
  );
};
